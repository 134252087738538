@import '../../../../assets/styles/variables';

.deleteWarningTableModal {
    font-family: $EY_Font;
    max-height: 100% !important;

    .deleteWarningTableHeader {
        border-style: unset !important;
        .editRangeWarningTableHead {
            font-size: 18px;
            font-weight: 400;
            line-height: 28px;
        }

        .editRangeWarningTableSubHead {
            font-size: 14px;
            font-weight: 300;
            line-height: 20px;
            color: $EY_Light_Grey_Color;
        }
        .motif-icon-button-table {
            align-self: start;
        }
    }
    .deleteWarningTableBody{
        .deleteWarningTableLines{
            font-family: $EY_Font;
            font-size: 16px;
            font-weight: 300;
            line-height: 24px;
        }

    }

    .deleteWarningTableFooter {
        display: table;
        text-align: right;

        .btnCancel,
        .btnConfirm {
            display: table-cell;
        }

        .btnConfirm {
            margin-left: 15px;
        }
    }

    .deleteTextTable {
        color: var(--Neutrals-Light-theme-900, $EY_Text_Color);
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
    }
    .motif-modal--header-icon-button {
        align-self: start !important;
    }
}