@import '../../../assets/styles/variables';

.no-doc {
  height: 52vh;
}

.document-item {
  display: flex;
  align-items: center;
  padding: 0.8rem 1.6rem;
  background-color: $white;
  font-family: $EY_Font;
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
}

.document-item.deleted {
  opacity: 0.4;
}

.documentList {
  margin-top: -8px;
  height: calc(100vh - 292px);
  overflow: auto;
  border: 1px solid $EY_Home_Border_Color;
  .doc-list {
    border-bottom: 1px solid $EY_Home_Border_Color;
  }
}

.body-document11 {
  height: 43vh !important;
  overflow: auto !important;
  padding: 0 !important;
}

.doc-card {
  border-top: none !important;
  width: 100%;
  height: inherit;

}

.header1 {
  border-top: none;
  height: 9vh;
}

.document-item:last-child {
  border-bottom: none;
}

.document-name {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  flex-grow: 1;
  word-break: break-word;
}

.document-status {
  display: inline-block;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  font-weight: 450;

}

.no-doc-info-container {
  height: 52vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;  
}

.no-doc-info-image {
  width: 48px;
  height: 48px;
  margin-bottom: 8px;
}

.no-doc-text-info {
  font-family: $EY_Font;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  text-align: center;
  color: $EY_Text_Color;
}

.no-doc-info {
  width: 221px;
}

.doc-text-info {
  font-family: $EY_Font;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  text-align: center;
}

.no-doc-description {
  font-family: $EY_Font;
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  text-align: center;
}

.no-doc-bold-description {
  font-family: $EY_Font;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  margin-top: 8px;
}

.listHeader {
  padding-left: 17px;
}

.motif-card-footer {
  justify-content: right !important;
}

.roleImage {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.motif-toast-text-container {
  text-align: left;
  font-weight: bold;
}

.motifButton {
  --btn--border-color: #F9F9FA !important;
}

.btnAccessDocument,
.btnRefreshDocument {
  border: none;
  gap: 10px;
  border-color: $white !important;
  font-family: $EY_Font;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
}
.doc-badge {
  width: 50px;
  height: 24px;
  padding: 3px 8px 3px 8px;
  gap: 6px;
  border-radius: 16px 0px 0px 0px;
  border: 1px 0px 0px 0px;
  font-family: $EY_Font;
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 18px !important;
  text-align: center !important;
}

.refreshMessage {
  position: absolute;
  bottom: 76px;
  width: 98% !important;
}

.loaderDiv {
  height: calc(100vh - 200px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .refreshListText {
    font-family: $EY_Font;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    text-align: center;
  }

  .refreshIcon {
    animation: rotation 2s linear infinite;
  }
}