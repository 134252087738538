@import './variables';

.genericModal {
    padding: 24px;
    min-width: 300px !important;
    max-width: 100vw !important;
    max-height: 94vh !important;
    .motif-modal-headline {
        padding: 0px;
    }
    .motif-modal-body  {
        padding: 0px;
        margin-top: 24px;
    }
    .motif-modal--header-icon-button {
        width: 3.43rem;
        height: 3.43rem;
        align-self: center;
    }
}
.confirmModal {
    padding: 24px;
    min-width: 20rem !important;
    max-width: 29rem !important;
    max-height: 94vh !important;
    .motif-modal-headline {
        padding: 0px;
    }
    .motif-modal-body  {
        padding: 0px;
        margin-top: 24px;
    }
    .motif-modal--header-icon-button {
        width: 3.43rem;
        height: 3.43rem;
        align-self: center;
    }
}
.modalNoHeaderBorder {
  .motif-modal-header {
    border: none;
  }
}
.modalHeaderVerticalPadding {
    .motif-modal-headline {
        padding: 10px 0px;
    }
}
.modalMotifFooter {
    .motif-footer  {
        padding: 24px 0px 0px 0px;
    }
}
.modalMotifFooterModal {
    .motif-modal-footer  {
        padding: 24px 0px 0px 0px;
    }
}
.startIconClose {
    .motif-modal--header-icon-button {
        align-self: start;
    }
}
.modalFooterBorder {
    .motif-modal-footer  {
        border-top: 1px solid $EY_Divider_Color;
    }
}
.genericFooter {
    position: fixed;
    bottom: 0px;
    left: 10px;
    width: calc(100% - 20px);
    background-color: $white;
    .footerInner {
        background-color: $EY_BG_Color;
        display: flex;
        gap: 10px;
        padding: 10px;
        border: 1px solid $EY_Home_Border_Color;
        margin-bottom: 10px;
        &.alignEnd {
            flex-direction: row-reverse;
            align-items: end;
        }
        &.alignCenter {
            justify-content: center;
        }
        .footerLinks{
            display: flex;
            align-items: center;
            gap: 3px;
        }
        .privacyNoticeSeparator{
            font-family: $EY_Font;
            font-size: 14px;
            color: $EY_Text_Color;
            font-style: normal;
            font-weight: 300;
            line-height: 20px;
        }
        .footerLink{ 
           font-family: $EY_Font;
           text-decoration: none;
           font-size: 12px;
           font-weight: 300;
           line-height: 18px;
           text-align: left;
           color: $EY_Link_Color;
        }
        .refreshButton{
            margin-left: auto;
        }
    }
}
.parentFlexLayer {
    display: flex;
    flex-flow: column;
}
.childFlexLayer {
    flex-grow: 1;
}
.tooltipWithoutClose {
    .motif-tooltip-top {
        margin-bottom: 1.25rem;
    }
    .motif-tooltip-bottom {
        margin-top: 1.25rem;
    }
    .motif-tooltip-left {
        margin-right: 1.25rem;
    }
    .motif-tooltip-right {
        margin-left: 1.25rem;
    }
    .motif-tooltip-content-children {
        font-family: $EY_Font;
    }
}
.motif-tooltip-inner .motif-icon-button {
    --tooltip-close-btn-icon-color: #656579;
    --tooltip-close-btn-hover-icon-color: #9897A6;
}

.tooltipWithoutCloseCenterText {
    .motif-tooltip-top {
        margin-bottom: 1.25rem;
    }
    .motif-tooltip-bottom {
        margin-top: 1.25rem;
    }
    .motif-tooltip-left {
        margin-right: 1.25rem;
    }
    .motif-tooltip-right {
        margin-left: 1.25rem;
    }
    .motif-tooltip-content-children {
        font-family: $EY_Font;
        text-align: center;
    }
}

.motif-tooltip-content-children {
    font-family: $EY_Font;
}

.btnGenAI, .iconNoThreshold, .noCursor {
    cursor: default;
}

.linkInline {
    display: inline;
}