@import '../../../../assets/styles/variables';


.document-list {
    margin-left: -1.7%;
    margin-right: -1.5%;
    margin-top: -1.5%;
    height: 51vh;
}

.no-doc {
    height: 52vh;
}

.document-item {
    display: flex;
    align-items: center;
    padding: 0.8rem 1.6rem;
    background-color: $white;
    font-family: $EY_Font;
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    .motif-tooltip-wrapper {
        width: 100%;
        .motif-tooltip-trigger-wrap {
            width: 100%;
        }
    }
    .textEllipsis {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.document-item.deleted {
    opacity: 0.5;
}

.tooltipWithoutCloseRow {
    .motif-tooltip-bottom {
        margin-top: 1rem;
        margin-left: 1.15rem;
    }
}

.documentList {
    margin-top: -8px;
    height: calc(100vh - 281px);
    overflow: auto;
    border: 1px solid $EY_Home_Border_Color;
    .doc-list {
        border-bottom: 1px solid $EY_Home_Border_Color;
    }
    .lengthy:hover {
        opacity: 0.6;
    }
}

.body-document11 {
    height: 43vh !important;
    overflow: auto !important;
    padding: 0 !important;
}

.doc-card {
    border-top: none !important;
    width: 100%;
    height: inherit;

}

.header1 {
    border-top: none;
    height: 9vh;
}

.document-item:last-child {
    border-bottom: none;
}

.document-name {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    flex-grow: 1;
}

.document-status {
    display: inline-block;
    padding: 0.25rem 0.5rem;
    border-radius: 4px;
    font-weight: 450;

}

.no-doc-info-container {
    height: calc(100vh - 240px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: 1px solid $EY_Home_Border_Color;
}

.no-doc-info-image {
    width: 48px;
    height: 48px;
    margin-bottom: 8px;
}

.no-doc-info {
    width: 221px;
}

.doc-text-info {
    font-family: $EY_Font;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    text-align: center;
}

.no-doc-description {
    font-family: $EY_Font;
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    text-align: center;
}

.no-doc-bold-description {
    font-family: $EY_Font;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    margin-top: 8px;
}

.motif-card-footer {
    justify-content: right !important;
}

.roleImage {
    width: 10px;
    height: 10px;
    border-radius: 50%;
}

.motif-toast-text-container {
    text-align: left;
    font-weight: bold;
}

.motifButton {
    --btn--border-color: #F9F9FA !important;
}

.btnAccessDocument,
.btnRefreshDocument {
    border: none;
    gap: 10px;
    border-color: $white !important;
    font-family: $EY_Font;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
}

.listHeader {
    padding-left: 17px;
}

.doc-badge {
    width: 50px;
    height: 24px;
    padding: 3px 8px 3px 8px;
    gap: 6px;
    border-radius: 16px 0px 0px 0px;
    border: 1px 0px 0px 0px;
    font-family: $EY_Font;
    font-size: 12px !important;
    font-weight: 400 !important;
    line-height: 18px !important;
    text-align: center !important;
}

.refreshMessage {
    position: absolute;
    bottom: 76px;
    width: 98% !important;
}

.loaderDiv {
    height: calc(100vh - 200px);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .refreshListText {
        font-family: $EY_Font;
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
        text-align: center;
    }

    .refreshIcon {
        animation: rotation 2s linear infinite;
    }
}



.docModal {
    font-family: $EY_Font;
    max-height: 90vh !important;

    .docHeader {
        padding-bottom: 0px;

        .docHead {
            font-size: 18px;
            font-weight: 400;
            line-height: 28px;
        }

        .docSubHead {
            font-size: 14px;
            font-weight: 300;
            line-height: 20px;
            color: $EY_Light_Grey_Color;
        }
    }

    .docModalBody {
        padding-top: 16px !important;
        padding-bottom: 12px !important;
    }

    .lblSelectRange {
        font-size: 15px;
        font-weight: 400;
        line-height: 20px;
        color: $EY_Light_Grey_Color;
    }

    .docFooter {
        display: table;
        text-align: right;

        .btnCancel,
        .btnNext {
            display: table-cell;
        }

        .btnNext {
            margin-left: 15px;
        }
    }
}