@import '../../../../assets/styles/variables';

.file-export {
    display: flex;
    margin-top: 16px;
    border: 1px solid $EY_Divider_Color;
    padding: 10px;
}

.file-export-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    word-break: break-word;
}

.file-name {
    flex: 1;
    text-align: left;
    font-family: $EY_Font;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}

.export-btn {
    background-color: transparent !important;
    border: none !important;
    display: flex;
    min-width: 0 !important;
    padding: 8px !important;

    &:hover {
        background-color: $EY_Hover_BG_Color !important;
    }
}

.upload-btn {
    background-color: transparent !important;
    border: none !important;
    display: flex;
    min-width: 0 !important;
    padding: 8px !important;

    &:hover {
        background-color: $EY_Hover_BG_Color !important;
    }
}