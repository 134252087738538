@import '../../../../assets/styles/variables';

.deletePromptModal {
    .motif-modal-body {
        font-family: $EY_Font;
        font-size: 14px;
        font-weight: 300;
        line-height: 24px;
    }

    .motif-modal-footer{
        display: table;
        text-align: right;

        .btnCancel,
        .btnConfirm {
            display: table-cell;
        }

        .btnConfirm {
            margin-left: 15px;
        }
    }
}