@import '../../../assets/styles/variables';

.projectContainer {
    font-family: $EY_Font;
    color:  $EY_Text_Color;
}
.projectDetailsBreadcrumb {
    border: 1px solid $EY_Divider_Color;
    margin-bottom: 8px;
    .btnBack {
        padding: 8px;
    }
    padding: 4px 8px;
}
.projectName {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    vertical-align: text-bottom;
    margin-left: 8px;
}
.projectTab {
    --tab-navigation--bg-color: #F9F9FA !important;
    --tab-navigation--border-color: #E6E6E9 !important;
    --tab-navigation-active--bg-color: #FFF;
    --tab-navigation-active--color: #2E2E38;
    font-family: $EY_Font;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    padding: 4px;
    display: grid !important;
    grid-auto-flow: column;
    border: 1px solid $EY_Home_Border_Color !important;
    border-bottom-width: 0px !important;
    &.motif-tab-navigation-horizontal .motif-tab-button.motif-active {
        border: 1px solid $EY_Border_Color !important;
        background-color: $white !important;
    }
    .motif-tab-button {
        border: 1px solid $EY_Tab_BG_Color !important;
    }
    .motif-tab-button:hover {
        box-shadow: none !important;
        outline: none !important;
        border: 1px solid $EY_Tab_BG_Color !important;
    }
}
.btnProjectTab {
    font-weight: 400 !important;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
}
.tablesTable {
    --table-header--bg-color: #F3F3F5;
    --table-odd-row--bg-color: $white;
    border: none;
    border-radius: 0px !important;
    box-shadow: none;
    .ag-row:hover {
        background-color: $EY_Hover_BG_Color;
    }
    height: -moz-calc(100vh - 241px) !important;
    height: -webkit-calc(100vh - 241px) !important;
    height: -o-calc(100vh - 241px) !important;
    height: calc(100vh - 241px) !important;
    .ag-header-cell-label {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color:  $EY_Text_Color;
    }
    .ag-header-cell, .ag-cell {
        padding: 0px 24px !important;
        word-break: break-word !important;
    }
    .tableName {
        font-weight: 300 !important;
    }
    div[col-id="tableName"], div[col-id="tableType"], div[col-id="metadata.uploadRange"] {
        cursor: pointer;
    }
    div[col-id="actions"] {
        justify-content: center;
        .ag-header-cell-label {
            justify-content: center;
        }
    }
    .ag-body-viewport {
        min-width: auto !important;
    }
    .motif-dropdown-menu {
        top: 92% !important;
        min-width: 125px !important;
        &::before {
            display: none !important;
        }
    }
    .ag-row-last:not(:first-child), .ag-row:nth-last-child(2):not(:first-child) {
        .motif-dropdown-menu {
            top: -155% !important;
            bottom: auto !important;
        }
    }
    .ag-row:nth-child(2) {
        .motif-dropdown-menu {
            top: 92% !important;
        }
    }
    .tableBtnMore {
        padding: 8px;
        margin: 8px;
        &:active, &:focus {
            background-color: var(--dropdown-menu-item-hover--bg-color);
        }
        .tableIconMore {
            max-width: none;
            max-height: none;
        }
    }
    .ag-body-horizontal-scroll, .ag-sticky-bottom {
        display: none;
    }
}
.tabContentTables {
    display: block;
}
.tabContentUsers {
    display: none;
}
.tabContentDocuments {
    display: none;
    .appHeader {
        border-radius: 0px;
        border-bottom: 0px;
    }
}
.btnAddTable, .btnThreshold {
    gap: 10px;
}
.imgThreshold {
    opacity: 50%;
}
.divider {
    background-color: $dividerColor;
    width: 1px;
}
.ag-overlay-no-rows-center {
    font-family: $EY_Font;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: $black;
}
.projectContent {
    .notifyToast {
        position: relative;
        z-index: 999;
        left: 15px;
        right: 15px;
        width: calc(100% - 30px);
        bottom: 60px;
        box-shadow: 0px 16px 32px 0px $EY_Toast_Box_Shadow_color;
    }
}
.iconedit {
    margin-right: 8px;
}
.icondelete {
    margin-right: 8px;
}
.document-download-delete{
    position: absolute;
    width: max-content;
    box-shadow: 0px 8px 16px 0px $EY_Toast_Box_Shadow_color;
}
#idTabContentTables .ag-cell-value, .ag-group-value{
    overflow: unset !important;
}
#idTabContentTables .ag-center-cols-viewport{
    overflow-y: auto !important;
}
#idTabContentTables .motif-table .ag-cell-wrapper{
    overflow: unset !important;
}
.textEllipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}