@import '../../../assets/styles/variables';

.tableDetailsContainer {
    font-family: $EY_Font;
    color: $EY_Text_Color;
    border: 1px solid $EY_Home_Border_Color;
}

.tableDetailsBreadcrumb {
    padding: 8px;

    display: flex;
    width: 100%;

    .fluid {
        flex: 1 1 20%;
        min-width: 0;
        align-self: center;

        .tableName {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    .btnBack {
        padding: 8px;
    }

    .tableName {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
        vertical-align: text-bottom;
        margin-left: 8px;
    }

    .tableActions {
        float: right;

        .btnTableActions {
            padding: 8px;             
        }

        .btnTableActions {
            padding: 8px;             
        }

        .btnTableActions.clicked {
            background-color: $EY_Home_Border_Color;
        }

        .btnWithBorder {
            border: 1px solid $EY_Divider_Color;
            border-radius: 0px;
        }

        .motif-icon-button:disabled {
            opacity: 50%;
        }
    }
}

.tabControl {
    --tab-navigation--bg-color: #F9F9FA !important;
    --tab-navigation--border-color: #E6E6E9 !important;
    --tab-navigation-active--bg-color: #FFF;
    --tab-navigation-active--color: #2E2E38;
    font-family: $EY_Font;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    padding: 4px;
    display: grid !important;
    grid-auto-flow: column;
    border: 1px solid $EY_Home_Border_Color !important;

    &.motif-tab-navigation-horizontal .motif-tab-button.motif-active {
        border: 1px solid $EY_Border_Color !important;
        background-color: $white !important;
    }

    .motif-tab-button {
        border: 1px solid $EY_Tab_BG_Color !important;
    }

    .motif-tab-button:hover {
        box-shadow: none !important;
        outline: none !important;
        border: 1px solid $EY_Tab_BG_Color !important;
    }
}

.btnTabControl {
    font-weight: 400 !important;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
}

.tabContentLineAnalysis {
    .contentLineAnalysis {
        height: calc(100vh - 395px);
        overflow-y: auto;
        border-top: 1px solid $EY_Home_Border_Color;
    }
    .accordionTitle {
        font-family: $EY_Font;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        margin-left: 12px;
    }

    .motif-accordion-trigger:disabled {
        opacity: 0.5;
    }
}

.warningIcon {
    margin-left: 10px;
}

.tabNavigationHeaders {
    vertical-align: super;
}

.tabContentTableAnalysis {
    display: none;
    height: calc(110vh - 352px);
}

.tabContentDescription {
    display: block;
    padding-top: 8px;
    overflow: auto;
    border:1px solid $EY_Divider_Color;
    border-top: 0px;
    border-bottom: 0px;
    height: calc(94vh - 260px);
}

.motif-accordion-icons {
    height: 30px !important;
    width: 30px !important;
}

.line-div {
    display: flex;
    align-items: center;
    border-top: 1px solid $EY_Home_Border_Color;
    justify-content: space-between; /* Push status-div to the right */
    padding: 8px 0;
    &:nth-child(1) {
        border-top: none;
    }
}

.msgAIContainer {
    padding-bottom: 8px;
    .iconWarningInfoCircle {
        padding: 10px 12px 10px 0px;
    }
    .msgAI {
        align-self: center;
    }
}
.toast-container{
    position: relative;
    width: 100%;
    margin-left: 4px;
    z-index: 2000;
    margin-top: -15%;
}
.lineAnalysisToast{
    margin-bottom: 15px !important;
    width:95% !important;
}
.tabContentQA {
    display: none;
    padding-top: 8px;
    overflow: auto;
    border: 1px solid $EY_Divider_Color;
    border-top: 0px;
    border-bottom: 0px;
    height: calc(94vh - 260px);
}
.commentary-toast {
    border: 1px solid $EY_Divider_Color;
    border-top: 0px;
}
.tabContentCommentary {
    display: none;
    padding-top: 8px;
    height: calc(94vh - 296px);
    overflow: auto;
}
.modal-body-lineanalysis{
    overflow: hidden !important;
    margin-top: 0px !important;
 }
.genericFooter {
    z-index: 999;

    .btnDownload,
    .btnUpload {
        padding: 8px;
        margin-right: 5px;
        border: 1px solid $dividerColor;
    }

    .btnDownload:disabled,
    .btnUpload:disabled {
        opacity: 50%;
    }

}

.body-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 25px;
}

.lineanalysisprogress {
    bottom: 82px !important;
    position: fixed !important;

    .motif-toast-icon-container img {
        align-self: start;
        margin-top: 12px;
    }

    .motif-toast-close-button {
        align-self: start;
        margin-top: 6px;
    }
}

.toast-content {
    font-size: 14px;
    font-weight: 300;
}

.img-failure {
    background-color: $EY_Failure_BG_Color;
    border-radius: 15px;
    padding: 7px;
}

.motif-modal-footer button+button {
    margin-top: 0px !important;
}

.analysis-footer {
    align-items: flex-end;
    padding-top: 0px !important;
    &:has(.transferToPPT) {
        padding-top: 8px !important;
    }
}

.lineAnalysisModal {
    min-height: 85vh;
}

.genericFooter .export-qa {
    width: calc(100% - 90px) !important;
}

.alignSpaceBetween {
    align-items: center;
    justify-content: space-between;
}

.info-icon {
    padding: 2px 5px 0px 0px;
    height: 21px;
}

.accordionContent {
    padding: 8px !important;

    .msgAIContainer {
        padding-bottom: 8px;

        .iconWarningInfoCircle {
            padding: 10px 12px 10px 8px;
        }

        .msgAI {
            align-self: center;
        }
    }
}
.contentQAOutline {
    padding: 16px !important;
    margin: 16px !important;
    border: 1px solid $EY_Home_Border_Color !important;
    border-radius: 8px !important;
}
.contentLayer {
    padding: 20px 16px;
    margin-bottom: 8px;

    .genAILayer {
        text-align: right;

        .genAIActions {
            padding: 8px;
            margin-left: 8px;
        }

        .iconCopy {
            margin-right: 8px;
        }
    }

    .content {
        font-family: $EY_Font;
        font-size: 16px;
        font-weight: 300;
        line-height: 24px;
        margin-top: 8px;

        .questionTitle,
        .questionSubTitle,
        .answerTitle {
            display: flex;
            font-weight: 400;

            .spacer {
                flex: 1 1 0;
            }

            .contentActions {
                padding: 8px;
            }

            .questionText,
            .answerText {
                align-self: center;
            }
        }

        .questionSubTitle,
        .answerTitle {
            font-size: 14px;
            line-height: 20px;
        }

        .questionSubTitle {
            margin-top: 16px;
        }

        .answerTitle {
            margin-top: 12px;
        }
    }

    .questionAnswerActions {
        text-align: right;
        margin-top: 12px;

        .btnArrowDown {
            display: flex;
            padding: 8px 14px;
            border: 1px solid $dividerColor;

            .viewSrcDocsText {
                font-family: $EY_Font;
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
            }

            .iconViewSrcDocs {
                margin-left: 8px;
            }

            &:disabled {
                opacity: 50%;
            }
        }

        .dropdownMore {
            .motif-dropdown-menu {
                min-width: 100px !important;
            }

            .motif-dropdown-menu[x-placement^=bottom-right] {
                right: 6px !important;
            }

            .motif-dropdown-menu::before {
                content: none !important;
            }
        }

        .dropdownViewSrcDocs {
            .motif-dropdown-menu {
                min-width: 50vw !important;
                max-width: 75vw !important;
                max-height: 135px;
                overflow-y: auto;
                right: 0px !important;

                .motif-dropdown-item {
                    cursor: default;
                }
            }
        }
    }
}

.noContentTitle {
    font-family: $EY_Font;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
}

.noContentBody {
    font-family: $EY_Font;
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    color: $EY_Light_Grey_Color;
    text-align: center;
}

.tabActionsLayer {
    text-align: right;
    background-color: $EY_BG_Color;
    border: 1px solid $EY_Divider_Color;
    border-top: 0px;
    padding: 10px;

    .btnDownload,
    .btnUpload {
        border: 1px solid $dividerColor;
        padding: 8px;
    }

    .btnUpload {
        margin-left: 8px;
    }

    .btnProceed {
        display: inline;
        vertical-align: top;
        margin-left: 8px;
    }
}

.errorMsg {
    width: 33px;
    max-width: fit-content !important;
    margin: 0px 4px 0px 0px;
}

.fldSelectAll {
    padding: 14px 0px 14px 10px;
    align-items: center;

    .motif-checkbox-label {
        margin-left: 20px;
        font-family: $EY_Font;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
    }
}

.lineAnalysisPopoverHeader {
    border: none !important;
}

.progress-loader {
    width: 24px;
    height: 24px;
    margin-top: 7px;
    margin-right: 7px;
    animation: rotation 2s linear infinite;
}

.status-div {
    display: flex;
    margin-left: auto;
    margin-right: 10px;
    .motif-tooltip-show {
        font-family: $EY_Font;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
    }
    .icon-check-green, .icon-check-red, .motif-tooltip-wrapper:has(.iconInfo) {
        padding: 8px;
    }
}

.accordion {
    display: flex;
    width: 96%;
}

.left-buttons {
    display: flex;
    align-items: center;
}

.no_nolineitems_div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap:4px;
    margin: 50px;
}

.no_nolineitems_text {
    font-family: $EY_Font;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    text-align: center;
}

.tableAnalysisDiv {
    padding: 12px;
    border-top: 1px solid $EY_Home_Border_Color;
    border-bottom: 1px solid $EY_Home_Border_Color;
    // border-radius: 6px;

    .tableAnalysisCommentry {
        font-family: $EY_Font;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
    }

    .tableAnalysisNoFoundTextDiv {
        gap: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: calc(118vh - 418px);
        flex-direction: column;
        border: 1px solid $EY_Home_Border_Color;
        border-radius: 6px;
    }

    .tableAnalysisNoFoundText {
        font-family: $EY_Font;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: center;
    }

    .tableAnalysisNoFoundClickText {
        font-family: $EY_Font;
        font-size: 14px;
        font-weight: 300;
        line-height: 20px;
        text-align: center;
        color: $EY_Light_Grey_Color;
    }

    .controlDiv {
        font-family: $EY_Font;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
        gap: 8px;
        padding: 6px 0px;
        display: flex;

        .spacer {
            flex: 1;
        }
    }

    .tableAnalysisText {
        height: calc(100vh - 400px);
        font-family: $EY_Font;
        font-size: 14px;
        font-weight: 300;
        line-height: 20px;
        text-align: left;
    }
}

.rowSelectAll {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.search-bar-container {
    display: flex;
    justify-content: space-between;
}

.icon-container {
    display: flex;
    width:50%;
    border-top: 1px solid $EY_Home_Border_Color;
    justify-content: space-evenly;
}

.search .motif-form-field .motif-input {
    padding-left: 3.528571rem !important;
    padding-right: 0.7142857143rem !important;
}

.search .iconSearch {
    left: 0.875rem;
    position: absolute;
    top: .8571428571rem;
}

.search .motif-form-field {
    position: relative;
    margin-bottom: 0;
}

.search-bar{
    width:50%;
    border-radius: 0;
}

.search-bar .motif-input {
    border-radius: 0.2857142857rem !important;
    border-bottom: none !important;
    border-left: none !important;  
}

.tableAnalysisLoaderDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 350px);
    flex-direction: column;

    .tableAnalysisProgress {
        animation: rotation 2s linear infinite;
    }

    .loaderText {
        font-family: $EY_Font;
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
        text-align: center;

    }
}

.tableContent {
    .notifyToast {
        left: 16px;
        width: calc(100% - 48px);
    }
    .btnTableActions {
        padding: 8px; 
        flex-grow: 1;
        text-align: center;            
    }
    
    .btnTableActions.clicked {
        background-color: $EY_Home_Border_Color;
    }

    .btnWithBorder {
        border: 1px solid $EY_Divider_Color;
        border-radius: 0px;
    }

    .motif-icon-button:disabled {
        opacity: 50%;
    }

}

.lineItemCheckbox {
    margin-left: 16px;
    align-self: center !important;
}
.button-group{
    display: flex;
    gap: 8px;
}
.btnGroupWithClear {
    display: flex;
    gap: 8px;
    align-items: center;
}
.msgForAddtoExcel {
    display: flex !important;
    align-items: center !important;
    .motif-inline-message-content {
        gap: 10px !important;
    }
    .iconInfoAddtoExcel {
        align-self: center;
    }
    .inlineMsgAddtoExcel {
        font-size: 14px;
        font-weight: 300;
        line-height: 20px;
        text-align: left;
    }
}
.addToExcelEllipses {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.clearAnalysisToast {
    position: fixed !important;
    bottom: 5.35rem !important;
    left: 49% !important;
    transform: translateX(-50%);
    z-index: 1000;
    .motif-toast-text-container {
        font-family: $EY_Font !important;
        font-size: 14px !important;
        font-weight: 400 !important;
        line-height: 20px !important;
    }
}
.transfertoppt {
    display: flex;
    justify-content: flex-end;
}
.tarnsferbuttoon {
    display: flex;
    gap: 12px;
}
.tarnsfertext {
    font-family: $EY_Font;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}
.sourceDocList {
    display: flex;
}

.sourceDocList span {
    margin: 6px 5px 0 0;
}

.sourceDocList.deleted {
    opacity: 0.5;
}

.tcTooltipTitle {
    font-family: $EY_Font;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    color: $EY_BG_Color;
    padding-bottom: 8px;
}

.tcTooltipBody {
    font-family: $EY_Font;
    font-size: 12px;
    font-weight: 300;
    line-height: 18px;
    color: $EY_Tooltip_Body_Color;
}