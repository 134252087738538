@import '../../../../assets/styles/variables';

.uploadQAModal {
    font-family: $EY_Font;
    max-height: 90vh !important;
    .uploadQAHeader {
        padding-bottom: 0px;
        .uploadQAHead {
            font-size: 18px;
            font-weight: 400;
            line-height: 28px;
        }
        .uploadQASubHead {
            font-size: 14px;
            font-weight: 300;
            line-height: 20px;
            color: $EY_Light_Grey_Color;
        }
    }
    .uploadQAModalBody {
        margin-top: 16px !important;
        padding-bottom: 12px !important;
    }
    .lblSelectRange {
        font-size: 15px;
        font-weight: 400;
        line-height: 20px;
        color: $EY_Light_Grey_Color;
    }
    .uploadQAFooter {
        display: table;
        text-align: right;
        .btnCancel, .btnNext {
            display: table-cell;
        }
        .btnNext {
            margin-left: 15px;
        }
    }
    .uploaderdiv {
        width: 100% !important;
        margin: 10px 0 0 0;
      }
      .dropzone-container {
        height: 30vh;
      }
      .uploadInProc div{
        width: 100%;
        text-align: left;
        .motif-progress-bar-label
        {
            margin: 0 45px 10px 0;
            text-align: right;
            font-weight: 700;
        };
        
      }
      .uploadDone div{
        .motif-file-uploader-item-label-wrapper
        {
            min-height: 40px;
        }
        .motif-file-uploader-item-file-name
        {
            text-align: left;
        }
      }

    .rangeMessage {
        font-size: 12px;
        font-weight: 300;
        line-height: 18px;
        color: $EY_Light_Grey_Color;
        padding-top: 8px;
    }
    .errorText {
        padding-top: 8px;
        color: $red;
        display: table-cell;
        .motif-error-icon {
            display: none;
        }
    }
    .iconError {
        position: absolute;
        right: 0.7142857143rem;
        &.topPosRange {
            top: 0.8571428571rem;
        }
        &.topPosTableName {
            top: 2.6571428571rem;
        }
        &.topPosCurrency {
            top: 2.6571428571rem;
        }
    }
    .fldRangeContainer {
        display: table;
        width: 100%;
        .fldRange {
            display: table-cell;
        }
        .btnRange {
            display: table-cell;
            text-align: -webkit-right;
        }
        .w70 {
            width: 70%;
        }
    }
    .detailsTitle {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
    }
    .detailsContent {
        font-size: 14px;
        font-weight: 300;
        line-height: 20px;
        color: $EY_Light_Grey_Color;
    }
    .fldLabel {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: $EY_Light_Grey_Color;
    }
    .fldTableType, .fldFrequency {
        .motif-select-input {
            padding-top: 0.2rem;
        }
    }
    .fldRange, .fldTableName, .fldTableType, .fldFrequency, .fldCurrency {
        padding: 12px 0px;
        .motif-form-field {
            margin: 0px 2px;
        }
        input {
            padding-right: 35px;
        }
    }
    .tableDetails {
        padding-top: 8px;
        color: var(--Neutrals-Light-theme-700, $EY_Light_Grey_Color);
        font-family: $EY_Font;
        font-weight: 300;
        line-height: 18px;
    }
    #selectrangeedit{
        margin-left: 90%;
        margin-top: -8%;
        position: absolute;
    }
    #selectrangeeditstep1{
        margin-left: 95%;
        margin-top: -5%;
        position: absolute;
    }
    .btnuploadQA, .btnThreshold {
        gap: 10px;
    }
    .motif-file-uploader-item-file-name
    {
        text-align: center;
        white-space: break-spaces;
        width: 100%;
        line-height: 1.5rem;
        overflow-wrap: anywhere;
    }
    .motif-file-uploader-item .motif-file-uploader-item-label .motif-icon
    {
        height: auto;
        width: auto;
        margin-right: 0.5rem;
    }
    .motif-file-uploader-item.uploadInProc .motif-file-uploader-item-label,
    .motif-file-uploader-item.uploadDone .motif-file-uploader-item-label {
        margin-bottom: 2rem;
        align-items: flex-start;
    }
    .motif-file-uploader-item.uploadInProc .motif-file-uploader-item-label .motif-icon,
    .motif-file-uploader-item.uploadDone .motif-file-uploader-item-label .motif-icon {
        min-width: 28px;
        min-height: 28px;
        align-self: flex-start;
    }
    .motif-file-uploader-item.uploadInProc .motif-close-icon,
    .motif-file-uploader-item.uploadDone .motif-close-icon {
        align-self: flex-start;
        margin-top: 0.6rem;
    }
    .motif-file-uploader-item.uploadInProc .motif-file-uploader-item-file-name,
    .motif-file-uploader-item.uploadDone .motif-file-uploader-item-file-name {
        padding-top: 0.1rem;
        padding-left: 0.1rem;
    }
    .motif-file-uploader-item.uploadInProc .motif-file-uploader-item-file-size,
    .motif-file-uploader-item.uploadDone .motif-file-uploader-item-file-size {
        padding-top: 0.1rem;
    }
    .motif-file-uploader-item.uploadInProc .motif-chip-button.motif-chip-removable,
    .motif-file-uploader-item.uploadDone .motif-chip-button.motif-chip-removable {
        padding: 0.785714rem 3.2857142857rem 0.7857142857rem 1rem;
    }
    .motif-file-uploader-item-custom-button-wrapper:has(> .iconProgressStep) {
        align-self: flex-start;
    }
    .iconProgressStep {
        width: 1.825rem !important;
    }
}

.uploadQACCModal {
    font-family: $EY_Font;
    max-height: 90vh !important;
    .uploadQACCHeader {
        padding-bottom: 0px;
        .uploadQACCHead {
            font-size: 18px;
            font-weight: 400;
            line-height: 28px;
        }
        .uploadQACCSubHead {
            font-size: 14px;
            font-weight: 300;
            line-height: 20px;
            color: $EY_Light_Grey_Color;
        }
    }
    .uploadQACCModalBody {
        padding-top: 16px !important;
        padding-bottom: 12px !important;
        .uploadQACCHead {
            font-size: 18px;
            font-weight: 400;
            line-height: 28px;
        }
    }
    .lblSelectRange {
        font-size: 15px;
        font-weight: 400;
        line-height: 20px;
        margin: 10px 0 0 0;
        color: $EY_Light_Grey_Color;
    }
    .uploadQACCFooter {
        display: table;
        text-align: right;
        .btnCancel, .btnNext {
            display: table-cell;
        }
        .btnNext {
            margin-left: 15px;
        }
    }
    .uploaderdiv {
        width: 100% !important;
        margin: 10px 0 0 0;
      }
      .dropzone-container {
        height: 30vh;
      }
      .uploadInProc div{
        width: 100%;
      }
    .rangeMessage {
        font-size: 12px;
        font-weight: 300;
        line-height: 18px;
        color: $EY_Light_Grey_Color;
        padding-top: 8px;
    }
    .errorText {
        padding-top: 8px;
        color: $red;
        display: table-cell;
        .motif-error-icon {
            display: none;
        }
    }
    .iconError {
        position: absolute;
        right: 0.7142857143rem;
        &.topPosRange {
            top: 0.8571428571rem;
        }
        &.topPosTableName {
            top: 2.6571428571rem;
        }
        &.topPosCurrency {
            top: 2.6571428571rem;
        }
    }
    .fldRangeContainer {
        display: table;
        width: 100%;
        .fldRange {
            display: table-cell;
        }
        .btnRange {
            display: table-cell;
            text-align: -webkit-right;
        }
        .w70 {
            width: 70%;
        }
    }
    .detailsTitle {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
    }
    .detailsContent {
        font-size: 14px;
        font-weight: 300;
        line-height: 20px;
        color: $EY_Light_Grey_Color;
    }
    .fldLabel {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: $EY_Light_Grey_Color;
    }
    .fldTableType, .fldFrequency {
        .motif-select-input {
            padding-top: 0.2rem;
        }
    }
    .fldRange, .fldTableName, .fldTableType, .fldFrequency, .fldCurrency {
        padding: 12px 0px;
        .motif-form-field {
            margin: 0px 2px;
        }
        input {
            padding-right: 35px;
        }
    }
    .tableDetails {
        padding-top: 8px;
        color: var(--Neutrals-Light-theme-700, $EY_Light_Grey_Color);
        font-family: $EY_Font;
        font-weight: 300;
        line-height: 18px;
    }
    #selectrangeedit{
        margin-left: 90%;
        margin-top: -8%;
        position: absolute;
    }
    #selectrangeeditstep1{
        margin-left: 95%;
        margin-top: -5%;
        position: absolute;
    }
    .btnuploadQA, .btnThreshold {
        gap: 10px;
    }
}