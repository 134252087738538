@import '../../../assets/styles/variables';

.project-div {
    border-top: 1px solid $EY_Home_Border_Color;
    &:last-child {
        border-bottom: 1px solid $EY_Home_Border_Color;
    }
}

.no-border {
    border-top: 0px;
}

.project-div h3 {
    margin: 0px;
}

.project-inner-div {
    display: flex;
    padding: 16px 24px;
    gap: 20%;
}

.project-name {
    cursor: pointer;
    color: $EY_Text_Color;
    font-family: $EY_Font;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    .motif-text-link:hover {
        border: none;
    }
}

.project-date {
    font-family: $EY_Font;
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    color: $EY_Light_Grey_Color;
}
.is-disabled-external{
    background: var(--Neutrals-Light-theme-25, $EY_BG_Color);
    display: flex;
    padding: 16px 24px;
    gap: 20%;
}
.is-disabled {
  pointer-events: none;
  opacity: 0.7; 
}
.iconNoThreshold {
    margin-left: auto;
    .motif-tooltip-wrapper {
        padding: 8px;
    }
}