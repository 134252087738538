@import '../../../../assets/styles/variables';

.TableRelationshipModal {
    min-height: 85vh;

    .ag-body-horizontal-scroll {
        display: none;
    }

    .lblSelectTableName {
        padding-bottom: 6px;
        color: $EY_Light_Grey_Color;
    }

    .selectedTableName {
        font-weight: 300;
        color: $EY_Text_Color;
    }

    .error-border {
        border: 1px solid $EY_Error_Color;
    }

    .lblSelectRow {
        padding-bottom: 6px;
    }

    .lblSelectRow,
    .lblSelectRange {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: $EY_Light_Grey_Color;
    }

    .lblSelectRange {
        margin-top: 24px;
    }

    .fldRange {
        padding: 6px 0px 12px 0px;

        input {
            padding-right: 35px;
        }

        .motif-form-field {
            margin-bottom: 0px;
        }
    }

    .fldNature {
        padding: 12px 0px;

        .motif-form-field {
            margin: 0px 2px;
        }

        input {
            padding-right: 35px;
        }

        .motif-input-invalid:focus {
            outline-color: $EY_Error_Color !important;
        }
    }

    .iconError {
        position: absolute;
        right: 0.7142857143rem;

        &.topPosRange {
            top: 0.8571428571rem;
            right: 2.7142857143rem !important;
        }

        &.topPosNature {
            top: 3.2571428571rem;
        }
    }

    .infoText {
        padding-top: 8px;
        color: var(--Neutrals-Light-theme-700, $EY_Light_Grey_Color);
        font-family: $EY_Font;
        font-weight: 300;
        line-height: 18px;
    }

    .fldLabel {
        padding-top: 8px;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: $EY_Light_Grey_Color;
    }
    .AddKPI_Btn_container {
    
        display: flex;
        margin-top: 4px;
        justify-content: flex-end;
        align-items: center;
    }
    .Discard_Btn_container{
        display: flex;
        margin-top: 4px;
        justify-content: flex-end;
        align-items: center;
    }

    .AddKPI_Btn {
        padding: 2px !important;
        border: none !important;
        &:hover{
            background-color: transparent;
        }
        }

        .DiscardKPI_Btn{
            padding: 2px !important;
            border: none !important;
            &:hover{
                background-color: transparent;
            }
        }
        .DiscardKPI_Btn:disabled {
            .DiscardKPIImage {
                opacity: 50%;
            }
        }
        .AddKPIbuttoon {
            display: flex;
            gap: 6px;
        }
        .DiscardKPIbuttoon{
            display: flex;
            gap: 6px;
        }        
        .Add_KPI_Btn_text {
            font-family: $EY_Font;
        }
        .Discard_KPI_Btn_text{
            font-family: $EY_Font;
        }

         .DiscardKPI_Btn:hover .DiscardKPIImage{
          filter: brightness(0) saturate(100%) invert(12%)
           sepia(91%) saturate(3433%) hue-rotate(354deg) brightness(94%) contrast(108%);

         }
         .DiscardKPI_Btn:hover .Discard_KPI_Btn_text{
            color:$red;
         }
        .Add_KPI_Btn_Blue_text{
            font-family: $EY_Font;
            color: $EY_Link_Color;
        }
        .kpiitem{
             margin-top: 12px;
        }
        .kpiheader
        {
                display: flex;
                align-items: end;
                border-bottom: 1px solid $dividerColor;
                justify-content: space-between;
        }

    .errorText {
        padding-top: 8px;
        color: $EY_Error_Color;
        display: table-cell;

        .motif-error-icon {
            display: none;
        }
    }

    .rangeErrorMessage {
        font-family: $EY_Font;
        font-size: 12px;
        font-weight: 300;
        line-height: 18px;
    }

    .additionalContextToastLayer {
        margin-bottom: 10px;
    }

    .tableAdditionalContext {
        height: 258px;
        --table-header--bg-color: #F3F3F5;
        --table-odd-row--bg-color: $white;
        border: none;
        border-radius: 0px;
        box-shadow: none;

        .ag-row:hover {
            background-color: $EY_Hover_BG_Color;
        }

        .ag-header-cell,
        .ag-cell {
            word-break: break-word !important;
        }
        .ag-body-viewport{
            overflow-y: auto;
            min-width: 0% !important;
        }
    }

    div[col-id="actions"] {
        justify-content: center;
        margin: 0px;

        .ag-header-cell-label {
            justify-content: center;
        }
    }

    .helpTooltipIcon {
        padding-left: 0.7142857143rem;
    }

    .btnDelete {
        padding: 8px;
    }
    .bodycenterinnerdivbottomtext{
        display: inline-block;
    }
}

.msgConfirm {
    font-family: $EY_Font;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
}

.btnBackKPI {
    margin-top: 10px !important;
    font-family: $EY_Font;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}
.KPI-number {

    font-family: $EY_Font;
    font-size: 14;
    font-weight: 400;
    color: $EY_Text_Color;
}
.bodycenterinnerdivtextkpi {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    color: $EY_Text_Color;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}
.tableRelationshipNoKPI{
    float: none;
    .add-relationshipkpi {
        display: inline-flex;
        align-items: center;
        background-color: $EY_Text_Color;
        padding: 0 20px;
        .add-relationkpi{
            color: $white;
        }
    }
}
.tableRelationshipFooterleftkpi{
    float: right;
    padding-left: 20px;
}
.add-relationshipkpi{
    display: inline-flex;
    align-items: center;
    background-color: $EY_Text_Color !important;
    padding: 0 20px !important;
}
.add-relationkpi{
    color: $white;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 11px;
}
.tableRelationshipFooterleftkpiback{
    float: left;
}
.addTableRelationshipHead {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
}
.remove-border{
    border-style: none !important;
}
.modalBodyAdditionalContext {
    display: table !important;
    .bodycenterdiv {
        display: table-cell;
        vertical-align: middle;
    }
    .bodycenterinnerdivbottomtext {
        display: block;
    }
}
.back-arrow{
    color: $EY_Text_Color;
    font-family: $EY_Font;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    padding-left: 8px
}

.icon-Info-circle{
    width: 32px;
    height: 16px;
    border-radius: 200px;
    padding-right: 8px;
    padding-left: 8px;
    gap: 7px;
    display: flex;
    float: right;
}

.icon-circle{
    cursor: pointer;
    width:18px;
    height: 20px;
    width: 14.333333969116211;
    height: 14.333333969116211;
    top: 0.83px;
    left: 0.83px;
}