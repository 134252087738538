.App {
  text-align: center;
  overflow: hidden;
}

.appHeader {
  width: 100%;
  .motif-card-header {
    padding: 8px;
  }
}

.motif-progress-loader-fullscreen {
  margin-top: 0 !important;
  z-index: 2000;
}

.motif-toast-fixed-bottom {
  z-index: 2000;
}

.HeaderContainer {
  display: flex;
  align-items: center;
  gap: 16px;
  align-self: stretch;
}