@import '../../../assets/styles/variables';

.header {
  height: 56px;
  justify-content: space-between;
  align-items: flex-start;
  flex-shrink: 0;
  border-bottom: 1px solid var(--Neutrals-Light-theme-100, $EY_Divider_Color);
  background: var(--Neutrals-Light-theme-00-White, $EY_BG_Color);

  width: 100%;

  .logomenu {
    float: left;
    gap: 8px;
    display: flex;
    align-self: stretch;
    align-items: center;
    padding-left: 10px;
    .logoDiv {
      display: flex;
      height: 44px;
      align-items: center;
      gap: 12px;

      .logo {
        display: flex;
        width: 40px;
        height: 40px;
        padding: 4.023px 5px 5.301px 5.001px;
        justify-content: center;
        align-items: center;
        border-radius: 2.5px;
        background: $EY_Text_Color;
      }
    }

    .projectTitle {
      color: $EY_Text_Color;
      font-family: $EY_Font;
      font-size: 16px;
      line-height: 24px;
      font-style: normal;
      font-weight: 400;

      .motif-text-link
      {
        text-decoration: underline !important;
        color: var(--text-link--color);
        display: inline-flex;
        background: transparent;
        font-weight: var(--text-link--font-weight);
        outline: none;
        position: relative;
        align-items: center;
        border: none;
        padding: 0;
      }
    }
  }

  .profileImageContainer {
    width: 100%;
    float: right;
    display: flex;
    padding: 6px 10px 6px 0px;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;

    .notificationImage {
      display: flex;
      padding: 12px;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      border: 1px solid var(--dev-only-transparent, rgba(0, 0, 0, 0));
      background: var(--dev-only-transparent, rgba(0, 0, 0, 0));
    }

    .NavDropdown {
      padding: 2px;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 8px;
      display: flex;
    }

    .Dropdown {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      display: inline-flex;
    }

    .Avatar {
      background-color: $white;
      border-radius: 200px;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 8px;
      display: inline-flex;
      border-color: $EY_Text_Color;
      border-width: 1px;
    }

    .AvatarImage {
      width: 40px;
      height: 40px;
      position: relative;
      border-radius: 50px;
    }

    .btnTableActions {
      padding: 12px 12px 8px 12px;
    }
  }
}

.tooltipTopPos {
  .motif-tooltip-bottom {
    margin-top: 1.75rem !important;
  }
}

.headerContainer {
  width: 275px;
  height: 240px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  display: inline-flex;
  z-index: 1;
  position: absolute;
  right: 20px;
  top: 20px;
}
.userMenupopupcontainer{
  margin-top: 33px;
}
.userMenuPopup {
  width: 275px;
  background: $white;
  box-shadow: 0px 8px 16px $EY_Popup_Box_Shadow;
  border-radius: 4px;
  border: 1px solid $EY_Divider_Color;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: inline-flex;
  padding: 10px;
}

.userMenuAvatar {
  display: flex;
  align-items: center;
}

.userAvatar {
  width: 40px;
  height: 40px;
  position: relative;
  border-radius: 50px;
}

.btnAvatar {
  padding: 4px;
  &:hover {
    background-color: transparent;
  }
}

.userAvatarUserMenuItem {
  margin-left: 8px;
  text-align: left !important;
  font-family: $EY_Font;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.userAvatarEmailMenuItem {
  margin-left: 1px;
  font-family: $EY_Font;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  color: $EY_Light_Grey_Color;
}

.logoutimg {
  top: 16%;
  left: 19%;
  vertical-align: middle;
}

.logout {
  right: 54%;
  top: 16%;
  text-decoration: none;
  margin-left: 5px;
  font-family: $EY_Font;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
}

.divider {
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: left;
}

.headercontainer {
  border: 1px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  overflow: hidden;
  padding: 0px 10px;
}

.logoutcontainer {
  display: flex;
  padding: 8px;
  &:hover {
    background-color: $EY_Hover_BG_Color;
  }
}

.usermenuwidthscratch {
  width: 100%;
}

.logoutDivider {
  margin-top: 12px;
  border: 1px solid $EY_Divider_Color;
}