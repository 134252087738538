@import '../../../assets/styles/variables';

.btnNewPrompt {
    margin-left:auto;
}

.imgAddNewPrompt {
    margin-right:0.5rem;
}

.personalPromptContainer{
    border-bottom-width: 0;

    .view-prompt-table {
        display: grid;
    }

    .personalPromptTable {
        height: calc(100vh - 240px);
        overflow-y: auto;
        --table-header--bg-color: #F3F3F5;
        --table-odd-row--bg-color: $white;
        border: none;
        border-radius: 0px;
        box-shadow: none;
        .ag-row:hover {
            background-color: $EY_Hover_BG_Color;
        }
        
        .motif-text-link {
            font-weight: 300;
        }
        .ag-header-cell-text {
            font-weight: 400;
            font-size: 14px;
            color: $EY_Text_Color;
        }
        .pagination-control-wrapper {
            display: none !important;
        }

        div[col-id="tableType"], div[col-id="promptName"] {
            cursor: pointer;
        }

        div[col-id="actions"] {
            justify-content: center;
            
            .ag-header-cell-label {
                justify-content: center;
            }
        }
        .ag-header-cell, .ag-cell {
            word-break: break-word !important;
        }
        .ag-body-viewport {
            min-width: auto !important;
        }
    }

    .deleteIcon {
        padding: 8px;
    }

    .notifyToast {
        bottom: 6.8rem;
        width: 90%;
        position: absolute;
        justify-self: center;
    }
}

.emptyFooter {
    height: 40px;
}

.system-prompt-container {
    height: calc(100vh - 240px);
    border-bottom-width: 0;
    overflow-y: auto;
}

.systemToast {
    display: grid;
}

.systemNotifyToast {
    bottom: 6.8rem;
    width: 90% !important;
    position: absolute;
    justify-self: center;
}