@import '../../../assets/styles/variables';

.msgAIContainer {
    padding-bottom: 8px;
    .iconWarningInfoCircle {
        padding: 10px 12px 10px 8px;
    }
    .msgAI {
        align-self: center;
    }
}

.container {
    border-radius: 5px;
}

.content {
    margin: 12px;
}
.commentry-content {
    margin: 0px !important;
}
.view-docs-div {
    display: flex;
    justify-content: end;
}

.page-title {
    font-weight: 400 !important;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
}

.top-bar {
    display: flex;
    justify-content: space-between;
}
.top-bar-commentry {
    padding: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    border-bottom: 1px solid $EY_Divider_Color;
    border-top: 1px solid $EY_Divider_Color;
}
.top-icons {
    margin-left: 0px;
    margin-right: 0px;
}
.commentry-paragraph {
    padding: 0px 16px 16px 16px;
    margin: 0px !important;
}
.commentry-overflow {
    overflow-y: auto !important;
    height: calc(100vh - 360px);
}
.copy-icon {
    margin-left: 10px;
}

.upside-down {
    transform: rotate(180deg);
}

.view-docs-btn {
    --btn--bg-color: #FFF !important;
    --btn--color: #2E2E38 !important;
    --btn--border-color: #C3C3CB !important;
    &:disabled {
        opacity: 50%;
    }
}

.viewSrcDocsText {
    font-family: $EY_Font;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}

.iconViewSrcDocs {
    margin-left: 8px;
}
.toast-div-commentry {
    position: fixed;
    bottom: 5.35rem;
    left: 49%;
    width: 86%;
    max-width: 96%;
    transform: translateX(-50%);
    z-index: 1000;
}

.motif-tooltip-content .motif-tooltip-content-children {
    margin-right: 0.1428571429rem !important;
}

.dropdownMore {
    .motif-dropdown-menu {
        min-width: 100px !important;
    }
    .motif-dropdown-menu[x-placement^=bottom-right] {
        right: 6px !important;
    }
    .motif-dropdown-menu::before {
        content: none !important;
    }
}

.dropdownViewSrcDocs {
    .motif-dropdown-menu {
        min-width: 50vw !important;
        .motif-dropdown-item {
            cursor: default;
        }
    }
    .motif-dropdown-menu[x-placement^=top-placement] {
        top: -136px !important;
        right: 0px !important;
        max-height: 134px;
        overflow-y: scroll;
    }
    .motif-dropdown-menu::before {
        content: none !important;
    }
}

.btnGenAI, .btnLike, .btnDislike, .btnMore, .btnTransfer, .btnCopy, .btnExpand {
    padding: 8px !important;
}
.btnTransfer:disabled {
    opacity: 50%;
}
.sourceDocList {
    display: flex;
}

.sourceDocList span {
    margin: 6px 5px 0 0;
}

.sourceDocList.deleted {
    opacity: 0.5;
}