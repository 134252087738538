@import '../../../../assets/styles/variables';

.editRangeWarningModal {
    font-family: $EY_Font;
    max-height: 100% !important;
    .editRangeWarningHeader {
        border-style:unset !important;
        .editRangeWarningHead {
            font-size: 18px;
            font-weight: 400;
            line-height: 28px;
        }
        .editRangeWarningSubHead {
            font-size: 14px;
            font-weight: 300;
            line-height: 20px;
            color: $EY_Light_Grey_Color;
        }
    }
    .editRangeWarningFooter {
        display: table;
        text-align: right;
        .btnCancel, .btnConfirm {
            display: table-cell;
        }
        .btnConfirm {
            margin-left: 15px;
        }
    }
    .editRangeText{
        color: var(--Neutrals-Light-theme-900, $EY_Text_Color);
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px; 
    }
}