.addPersonalPromptModal {

    .addPersonalPromptHeader{
        padding-bottom: 24px;
    }

    .addPersonalPromptBody{
        margin-top: 16px !important;
        padding-right: 12px !important;
        padding-left: 2px !important;
        margin-right: -20px;
    }

    .motif-modal-footer{
        display: table;
        text-align: right;

        .btnCancel,
        .btnConfirm {
            display: table-cell;
        }

        .btnConfirm {
            margin-left: 15px;
        }
    }
}