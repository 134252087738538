@import '../../../../assets/styles/variables';

.modal-buttons {
    flex-direction: row !important;
    justify-content: right !important;
}

.motif-modal-footer button + button {
    margin-top: 0px;
}

.motif-icon svg {
    margin-bottom: -3px;
    margin-right: 3px;
}

.fileNameContainer {
    font-family: $EY_Font;
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    .fileLabel {
        font-family: $EY_Font;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
    }
}