@import '../../../../assets/styles/variables';

.editSystemPrompt {
    margin: 0.8rem;
    
    .fldLabel {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: $EY_Light_Grey_Color;
    }

    .fldName {
        margin-top: 0.3rem;
        margin-bottom: 1.5rem;
        .motif-form-field {
            margin: 0px 0px;
        }
        input {
            padding-right: 35px;
        }
        .motif-select-input {
            padding-top: 0.2rem;
        }
        .motif-input-invalid:focus {
            outline-color: $EY_Error_Color !important;
        }
        textarea {
            padding-top: 4px;
            min-height: 81px;
        }
    }
}