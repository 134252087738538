@import '../../../../assets/styles/variables';

.TableRelationshipModal {
    font-family: $EY_Font;
    max-height: 100% !important;
    .TableRelationshipHeader {
        .TableRelationshipHead {
            font-size: 18px;
            font-weight: 400;
            line-height: 28px;
        }
    }
    .lblSelectTableName, .lblSelectRow, .lblSelectTables {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: $EY_Light_Grey_Color;
        padding-bottom: 8px;
    }
    .valSelectTableName {
        font-size: 14px;
        font-weight: 300;
        line-height: 20px;
        color: $EY_Text_Color;
    }
    .lblSelectRow, .lblSelectTables {
        padding-top: 12px;
    }
    .TableRelationshipFooter {
        display: table;
        .btnCancel, .btnConfirm {
            display: table-cell;
            // text-align: right;
        }
        .btnConfirm {
            margin-left: 15px;
        }
    }
    .fldLabel {
        font-size: 14px;
        font-weight: 300;
        line-height: 20px;
        color: $EY_Text_Sub_Color;

    }
    .motif-select-input {
        padding-top: 0px;
    }
    .bodyheaderdiv{
        margin-bottom: 2%;
    }
    .bodycenterdivrelation{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        gap: 8px;
        align-self: stretch;
        margin-bottom: 5%;
    }
    .bodycenterinnerdiv{
        display: flex;
        padding: 12px 8px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        align-self: stretch;
    }
    .tableRelationshipNoRelation{
        float: none;
        .add-relationship-btn {
            display: inline-flex;
            align-items: center;
            background-color: $EY_Text_Color;
            padding: 0 20px;
            .add-relation-rel{
                color: $white;
                font-size: 14px;
                 font-style: normal;
                 font-weight: 400;
                 line-height: 11px;
            }
        }
    }
    .tableRelationshipFooterleftrelationback{
        float: left;
    }
    .btnBackRelation{
        margin-top: 10px !important;
        font-family: $EY_Font;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
    }
    .errorToastMesg{
        position: fixed;
        left: 50%;
        width: 76%;
        bottom:21%;
        max-width: 85%;
        transform: translateX(-50%);
        --toast--border-radius: 8px;
        z-index: 1000;
        box-shadow: 0px 16px 32px 0px $EY_Toast_Box_Shadow_color;
        .motif-toast-icon-container {
            padding-top: 7px;
            display: table;
            position: absolute;
        }
        .motif-toast-content {
            align-items: flex-start;
            flex-direction: column-reverse;
            .motif-toast-close-button{
                max-height: 25px;
                margin-top: 4px;
            }
        }
        .motif-toast-text-container {
            margin-left: 14px;
            margin-right: 14px;
            width: calc(100% - 28px) !important;
            .errorToast-heading{
                font-family: $EY_Font;
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                text-align: left;
                color: $EY_Toast_Color;
            }
            .errorToast-description{
                font-family: $EY_Font;
                font-size: 14px;
                font-weight: 300;
                line-height: 20px;
                text-align: left;
                color: $EY_Toast_Color;
            }
        }
    }
    .imageborder{
        display: flex;
        padding: 14px;
        align-items: flex-start;
        border-radius: 200px;
        background: var(--Neutrals-Light-theme-50, $EY_Hover_BG_Color);
    }
    .bodycenterinnerdivtext{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        align-self: stretch;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        color: $EY_Text_Color;
    }
    .bodycenterinnerdivbottomtext{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        align-self: stretch;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        color: $EY_Light_Grey_Color;
    }
    .addrelation{
        background: none;
        color: var(--Neutrals-Light-theme-900, $EY_Text_Color);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
    }
    .tableRelationshipFooterleft{
        float: left;
    }
    .tableRelationshipFooterRight{
        float: right;
    }
    .addrelationicon{
        margin-right: 8px;
    }
    .relation-accordian{
        margin-top: 12px;
    }
    .add-relationship{
        display: inline-flex;
        align-items: center; 
        background: transparent;       
    }
    .trashrelation{
        cursor: pointer;
        width: 20px;
        height: 20px;
    }
    .add-relation{
        color: var(--Neutrals-Light-theme-900, $EY_Text_Color);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
    }
    .cellCenter .ag-cell-wrapper {
        justify-content: center;
      }
    .notifyToast {
        position: relative;
        z-index: 999;
    }
    #relationaccordian .motif-accordion-content{
        padding: unset !important;
    }
    #relationaccordian .motif-table .pagination-control-wrapper{
        padding: unset !important;
    }
    .relaccordian{
        margin-bottom: 10px;
    }
    .relation-column{
        display: flex;
        justify-content: flex-start;
    }
    .relation-column-center{
        display: flex;
        justify-content: flex-start;
        margin-left: 10px;
    }
    .tableRelationshipToastLayer {
        margin-bottom: 10px;
    }
    .tableRelationship {
        --table-header--bg-color: #F3F3F5;
        --table-odd-row--bg-color: $white;
        border: none;
        border-radius: 0px;
        box-shadow: none;
        .ag-row:hover {
            background-color: $EY_Hover_BG_Color;
        }
        .ag-header-cell, .ag-cell {
            word-break: break-word !important;
        }
    }
    div[col-id="actions"] {
        justify-content: center;
        margin: 0px;
        .ag-header-cell-label {
            justify-content: center;
        }
    }
    .btnDelete {
        padding: 8px;
    }
}