@import '../../../assets/styles/variables';

.memberListHeader {
  width: 100%;
}

.memberListFooter {
  width: 100%;
  background-color: $EY_BG_Color !important;
}

.motif-card-footer {
  justify-content: right !important;
}

.avtImage {
  width: 30px;
  height: 30px;
  position: relative;
  border-radius: 48px;
}

.roleImage {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.avtColumn {
  display: flex;
  align-items: center;
}

.Namedisplay {
  margin-left: 8px;
  text-align: left;
  font-family: $EY_Font;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;

}

.Emaildisplay {
  margin-left: 1px;
  font-family: $EY_Font;
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  text-align: left;

}

.motif-toast-text-container {
  text-align: left;
  font-weight: bold;
}

.motifButton {
  --btn--border-color: #F9F9FA !important;
}

.memberGrid {
  margin-top: -8px;

  .motif-table {
    height: calc(100vh - 282px);
  }
}

.btnAccessMember,
.btnRefresh {
  border: none;
  gap: 10px;
  border-color: $white !important;
  font-family: $EY_Font;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
}

.listHeader {
  padding-left: 17px;
}

.memberTable {
  --table-header--bg-color: #F3F3F5;
  --table-odd-row--bg-color: $white;
  border: none;
  border-radius: 0px;
  box-shadow: none;
  .ag-row:hover {
      background-color: $EY_Hover_BG_Color;
  }

  .ag-header.ag-pivot-off{
    display: none;
  }

  .ag-header-cell-label {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: $EY_Text_Color;
  }

  .ag-header-cell,
  .ag-cell {
    padding: 0px 24px !important;
    font-family: $EY_Font;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    word-break: break-word !important;
  }
  div[col-id="role"] {
      justify-content: end;
      .ag-header-cell-label {
          justify-content: center;
      }
  }
  .ag-body-viewport {
      min-width: auto !important;
  }
}

.badge {
  width: 24px;
  height: 24px;
  padding: 3px 8px 3px 8px;
  gap: 6px;
  border-radius: 16px 0px 0px 0px;
  border: 1px 0px 0px 0px;
  font-family: $EY_Font;
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 18px !important;
  text-align: center !important;
}

.refreshMessage {
  position: absolute;
  bottom: 76px;
  width: 98% !important;
}

.loaderDiv {
  height: calc(100vh - 200px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .refreshListText {
    font-family: $EY_Font;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    text-align: center;
  }

  .refreshIcon {
    animation: rotation 2s linear infinite;
  }
}
