@import '../../../assets/styles/variables';

.body-section {
    border: 1px solid $EY_Home_Border_Color;
    border-top: 0px;
    height: calc(100vh - 232px);
    overflow-y: auto;
}

.tab-section {
    border: 1px solid $EY_Home_Border_Color;
    background-color: $EY_BG_Color;
    padding: 4px;
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    .motif-tab-navigation-horizontal 
    .motif-tab-button.motif-active {
        border: 1px solid $EY_Home_Border_Color;
        background-color: $white;
        color: $EY_Text_Color;
    }
}

.tab-section-projects {
    border: 1px solid $EY_Home_Border_Color;
    background-color: $EY_BG_Color;
    padding: 4px;
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    .motif-tab-navigation-horizontal 
    .motif-tab-button.motif-active {
        border: 1px solid $EY_Home_Border_Color;
        background-color: $white;
        color: $EY_Text_Color;
    }
    
}

.toast-div {
    position: fixed;
    bottom: 5.35rem;
    left: 50%;
    width: 90%;
    max-width: 96%;
    transform: translateX(-50%);
}

.tab-nav {
    --tab-navigation-horizontal-button--border-style: 0px;
    --tab-navigation-horizontal-button-hover--border-style: 0px;
    --tab-navigation-horizontal-button--border-width: 0px;
    --tab-navigation-horizontal-button-active--border-style: 1px solid #d7d7dc;
    --tab-navigation-horizontal-button-active-hover--border-style: 0px;
}

.tab-active {
    font-weight: 400;
}

.tab-button {
    color: $EY_Light_Grey_Color;
    font-family: $EY_Font;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}

.refresh-icon {
    padding-right: 7px;
}

#btnRefreshProjectsList {
    border: none;
}
.search-section {
    .motif-form-field {
        margin-bottom: 0px;
        .motif-input {
            padding-left: 2.5285714286rem !important;
            padding-right: 0.7142857143rem !important;
            border-top: 0px !important;
            border-radius: 0px !important;
        }
        .motif-search-input-wrapper {
            .motif-search-input-icon {
                left: 0.7142857143rem;
            }
        }
    }
    .iconSearch {
        position: absolute;
        left: 0.7142857143rem;
        top: 0.8571428571rem;
    }
}