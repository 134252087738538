@import '../../../../assets/styles/variables';

.aiGuidanceModal {
    font-family: $EY_Font;
    .aiGuidanceHeader {
        min-height: auto !important;
        .aiGuidanceHead {
            font-size: 18px;
            font-weight: 400;
            line-height: 28px;
            margin-top: 20px;
        }
    }
    .aiGuidanceBody {
        font-family: $EY_Font;
        font-size: 16px;
        font-weight: 300;
        line-height: 24px;
        .linkAIGuidance {
            font-weight: 300;
        }
    }
}