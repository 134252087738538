@import '../../../../assets/styles/variables';

.clearAnalysisModal {
    font-family: $EY_Font;
    min-height: -webkit-fill-available;

    .clearAnalysisHeader {
        border-style: unset !important;
        .motif-icon-button-table {
            align-self: start;
        }
    }
    .clearAnalysisBody{
        overflow: hidden !important;
        .clearAnalysisTextLines{
            font-family: $EY_Font;
            font-size: 16px;
            font-weight: 300;
            line-height: 24px;
            text-align: left;

        }
        .clearListHeaderNames{
            height: 200px;
            overflow-y: auto;
        }

    }

    .clearAnalysisFooter {
        display: table;
        text-align: right;

        .btnCancel,
        .btnConfirm {
            display: table-cell;
        }

        .btnConfirm {
            margin-left: 15px;
        }
    }

    .clearTextHeading {
        color: var(--Neutrals-Light-theme-900, $EY_Text_Color);
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
        margin-top: 20px;
    }
    .motif-modal--header-icon-button {
        align-self: start !important;
    }
}