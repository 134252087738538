@import '../../../../assets/styles/variables';

.addTableModal {
    font-family: $EY_Font;
    min-height: 85vh;
    .addTableHeader {
        padding-bottom: 24px;
        .addTableHead {
            font-size: 18px;
            font-weight: 400;
            line-height: 28px;
        }
        .addTableSubHead {
            font-size: 14px;
            font-weight: 300;
            line-height: 20px;
            color: $EY_Light_Grey_Color;
        }
    }
    .addTableModalBody {
        margin-top: 16px !important;
        padding-bottom: 12px !important;
    }
    .lblSelectRange {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: $EY_Light_Grey_Color;
    }
    .addTableFooter {
        display: table;
        text-align: right;
        .btnCancel, .btnNext {
            display: table-cell;
        }
        .btnNext {
            margin-left: 15px;
        }
    }
    .rangeMessage {
        font-size: 12px;
        font-weight: 300;
        line-height: 18px;
        color: $EY_Light_Grey_Color;
        padding-top: 8px;
    }
    .errorText {
        padding-top: 8px;
        color: $red;
        display: table-cell;
        .motif-error-icon {
            display: none;
        }
    }
    .iconError {
        position: absolute;
        right: 0.7142857143rem;
        &.topPosRange {
            top: 0.8571428571rem;
        }
        &.topPosTableName {
            top: 2.6571428571rem;
        }
        &.topPosCurrency {
            top: 2.6571428571rem;
        }
        &.topPosUnit {
            top: 2.6571428571rem;
        }
    }
    .fldRangeContainer {
        display: table;
        width: 100%;
        .fldRange {
            display: table-cell;
        }
        .btnRange {
            display: table-cell;
            text-align: -webkit-right;
        }
        .w70 {
            width: 70%;
        }
    }
    .detailsTitle {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
    }
    .detailsContent {
        font-size: 14px;
        font-weight: 300;
        line-height: 20px;
        color: $EY_Light_Grey_Color;
    }
    .fldLabel {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: $EY_Light_Grey_Color;
    }
    .fldTableType, .fldFrequency {
        .motif-select-input {
            padding-top: 0.2rem;
        }
    }
    .fldRange, .fldTableName, .fldTableType, .fldFrequency, .fldCurrency, .fldUnit {
        padding: 12px 0px;
        .motif-form-field {
            margin: 0px 2px;
        }
        input {
            padding-right: 35px;
        }
        .motif-input-invalid:focus {
            outline-color: $EY_Error_Color !important;
        }
    }
    .tableDetails {
        padding-top: 8px;
        color: var(--Neutrals-Light-theme-700, $EY_Light_Grey_Color);
        font-family: $EY_Font;
        font-weight: 300;
        line-height: 18px;
    }
    #selectrangeedit {
        margin-left: 90%;
        margin-top: -8%;
        position: absolute;
    }
    #selectrangeeditstep1 {
        margin-left: 95%;
        margin-top: -5%;
        position: absolute;
    }
}