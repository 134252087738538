/*
    Variables for colors & fonts
    Created Date: 20-06-2024
*/
//  Fonts
$EY_Font: EYInterstate;

//  Colors
$white: #FFF;
$black: #000;
$red: #C70117;

//  Custom Colors
$dividerColor: #C3C3CB;
$EY_Text_Color: #2E2E38;
$EY_Border_Color: #C3C3CB;
$EY_Light_Grey_Color: #656579;
$EY_BG_Color: #F9F9FA;
$EY_Home_Border_Color: #D7D7dC;
$EY_Text_Sub_Color: #7F7F91;
$EY_Divider_Color: #E6E6E9;
$EY_Tooltip_Body_Color: #9897A6;
$EY_Error_Color: #EA011D;
$EY_Toast_Box_Shadow_color: #23232F14;
$EY_Tab_BG_Color: #F8F9FA;
$EY_Link_Color: #0F69AE;
$EY_Hover_BG_Color: #F3F3F5;
$EY_Failure_BG_Color: #FCF5F3;
$EY_Toast_Color: #A40011;
$EY_FG_Border_Color: #CCC;
$EY_Popup_Box_Shadow: rgba(35, 35, 47, 0.08);