@import '../../../../assets/styles/variables';

.thesholdsModal {
    min-height: 85vh;
    font-family: $EY_Font;
    max-height: 100% !important;
    .thresholdsHeader {
        .thresholdsHead {
            font-size: 18px;
            font-weight: 400;
            line-height: 28px;
        }
    }
    .thresholdsTab {
        display: grid;
        grid-auto-flow: column;
        .motif-active {
            color: $EY_Text_Color !important;
            font-weight: 400 !important;
        }
    }
    .tabContentGlobal {
        display: block;
        .infoText {
            line-height: 20px;
            margin-top: 32px;
        }
        .lblAbsThreshold, .lblPercThreshold {
            font-weight: 400;
            line-height: 20px;
            margin-top: 12px;
        }
        .fldAbsThreshold, .fldPercThreshold {
            margin: 8px 2px;
            .iconHelp {
                position: absolute !important;
                right: 0.7142857143rem !important;
                top: 0.8571428571rem !important;
            }
        }
        .fldAbsThresholdNonAdmin, .fldPercThresholdNonAdmin {
            margin: 6px 2px 0px 2px;
            .motif-tooltip-wrapper {
                position: absolute !important;
                right: 0.7142857143rem !important;
                top: 0.8571428571rem !important;
            }
        }
        .fldPercThreshold {
            .field-group {
                margin-bottom: 0.25rem;
                &.motif-input-invalid {
                    border-width: 1px !important;
                    border-color: $EY_Error_Color !important;
                    outline-color: $EY_Error_Color !important;
                    &:focus {
                        outline-color: $EY_Error_Color !important;
                    }
                }
            }
            .errorText {
                color: $red;
                padding-bottom: 0.5rem;
                display: table-cell;
                .motif-error-icon {
                    display: none;
                }
            }
            .iconError {
                position: absolute;
                right: 0.7142857143rem;
                &.topPosThreshold {
                    top: 0.8571428571rem;
                }
            }
        }
        .motif-inline-message img {
            position: relative;
            width: 1.5714285714rem;
            height: 1.5714285714rem;
            min-width: 1.5714285714rem;
            margin-right: 0.5714285714rem;
            fill: var(--inline-icon-color);
            --inline-icon-color: var(--inline-info-icon-color);
        }
    }
    .tabContentTable {
        display: none;
        .fldSearch {
            margin: 16px 2px 0px 2px;
            input {
                padding-left: 35px;
            }
            .iconSearch {
                position: absolute;
                left: 0.7142857143rem;
                top: 0.8571428571rem;
            }
        }
        .accordionTable {
            .motif-accordion-trigger:disabled {
                opacity: 0.5;
                cursor: no-drop;
            }
            .accordionTitle {
                font-family: $EY_Font;
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
            }
            .iconHelp {
                position: absolute !important;
                right: 0.7142857143rem !important;
                top: 0.8571428571rem !important;
            }
            .fldPercThreshold {
                .field-group {
                    margin-bottom: 0.25rem;
                    &.motif-input-invalid {
                        border-width: 1px !important;
                        border-color: $EY_Error_Color !important;
                        outline-color: $EY_Error_Color !important;
                        &:focus {
                            outline-color: $EY_Error_Color !important;
                        }
                    }
                }
                .errorText {
                    color: $red;
                    padding-bottom: 0.5rem;
                    display: table-cell;
                    .motif-error-icon {
                        display: none;
                    }
                }
                .iconError {
                    position: absolute;
                    right: 0.7142857143rem;
                    &.topPosThreshold {
                        top: 0.8571428571rem;
                    }
                }
            }
        }
    }
    .thresholdsFooter {
        flex-direction: row;
        align-self: end;
        .btnCancel, .btnNext {
            display: table-cell;
        }
        .btnConfirm {
            margin: 0px 0px 0px 15px;
        }
    }
}
.tooltipAbsThresholdTitle {
    font-family: $EY_Font;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    color: $EY_BG_Color;
}
.tooltipAbsThrehsoldBody {
    font-family: $EY_Font;
    font-size: 12px;
    font-weight: 300;
    line-height: 18px;
    color: $EY_Tooltip_Body_Color
}