@import '../../../assets/styles/variables';

.errorToast-div {
    position: fixed;
    bottom: 80px;
    left: 50%;
    width: 100%;
    max-width: 85%;
    transform: translateX(-50%);
    --toast--border-radius: 8px;
    z-index: 1000;
    box-shadow: 0px 16px 32px 0px $EY_Toast_Box_Shadow_color;
    .motif-toast-icon-container {
        padding-top: 7px;
        display: table;
        position: absolute;
    }
    .motif-toast-content {
        align-items: flex-start;
        flex-direction: column-reverse;
        .motif-toast-close-button{
            max-height: 25px;
            margin-top: 4px;
        }
    }
    .motif-toast-text-container {
        margin-left: 14px;
        margin-right: 14px;
        width: calc(100% - 28px) !important;
        .errorToast-text1 {
            font-family: $EY_Font;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            text-align: left;
            color: $EY_Toast_Color;
        }
        .errorToast-text2 {
            font-family: $EY_Font;
            font-size: 14px;
            font-weight: 300;
            line-height: 20px;
            text-align: left;
            color: $EY_Toast_Color;
        }
    }
}