@import '../../../assets/styles/variables';

.parent-noprojects-div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.parent-nopaans-div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 145px);
}
.parent-search-icon-div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.search-icon-div {
    background-color: $EY_BG_Color;
    border-radius: 20px;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.parent-noprojects-text-div {
    word-wrap: break-word;
    color: $EY_Text_Color;
    font-family: $EY_Font;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    text-align: center;
    padding-top: 17px;
}

.parent-accessplatform-div {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
}

.parent-statement-div {
    word-wrap: break-word;
    color: $EY_Light_Grey_Color;
    font-family: $EY_Font;
    font-size: 12px;
    padding-top: 10px;
    text-align: center;
}