@import '../../../../assets/styles/variables';

.modal-buttons {
    flex-direction: row !important;
    justify-content: right !important;
}

.inputThreshold {
    border: none !important;
    outline: none;
    flex: 1;
    //--input-hover--bg-color:red
    &:focus {
        outline: none !important;
    }
}

.helpTooltipIcon {
    padding-right: 0.7142857143rem;
    padding-top: 6px;
}

.errorTooltipIcon {
    padding-right: 0.7142857143rem;
}

.field-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid $EY_FG_Border_Color;
    border-radius: 0.1428571429rem;
    width: 100%;
}

.field-group:hover {
    background-color: $EY_Hover_BG_Color;
    /* Background color on hover */
}

.motif-input-component {
    width: 100%;
}

.fldPercThreshold {
    .field-group {
        margin-bottom: 0.25rem;
        &.motif-input-invalid {
            border-width: 1px !important;
            border-color: $EY_Error_Color !important;
            outline-color: $EY_Error_Color !important;
            &:focus {
                outline-color: $EY_Error_Color !important;
            }
        }
    }
    .errorText {
        color: $red;
        padding-bottom: 0.5rem;
        display: table-cell;
        .motif-error-icon {
            display: none;
        }
    }
    .iconError {
        position: absolute;
        right: 0.7142857143rem;
        &.topPosThreshold {
            top: 0.8571428571rem;
        }
    }
}
