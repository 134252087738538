@import '../../../../assets/styles/variables';

.editPersonalPrompt {

    .fldLabel {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: $EY_Light_Grey_Color;
    }

    .fldName {
        margin-top: 0.3rem;
        margin-bottom: 1.5rem;
        .motif-form-field {
            margin: 0px 0px;
        }
        input {
            padding-right: 35px;
        }
        .motif-select-input {
            padding-top: 0.2rem;
        }
        .motif-input-invalid:focus {
            outline-color: $EY_Error_Color !important;
        }
        textarea {
            padding-top: 4px;
            min-height: 81px;
        }
    }

    .fldDetails {
        padding-top: 0.35rem;
        color: var(--Neutrals-Light-theme-700, $EY_Light_Grey_Color);
        font-family: $EY_Font;
        font-weight: 300;
        line-height: 18px;
    }

    .iconError {
        position: absolute;
        right: 0.7142857143rem;
        &.topPosPromptName {
            top: 2.6571428571rem;
        }
    }

    .errorText {
        padding-top: 8px;
        color: $red;
        display: table-cell;
        .motif-error-icon {
            display: none;
        }
    }
}