@import '../../../../assets/styles/variables';

.analysisSettingsModal {
    font-family: $EY_Font;
    min-height: 85vh;
    .analysisSettingsHeader {
        padding-bottom: 24px;
        .analysisSettingsHead {
            font-size: 18px;
            font-weight: 400;
            line-height: 28px;
        }
        .analysisSettingsSubHead {
            font-size: 16px;
            font-weight: 300;
            line-height: 24px;
            color: $EY_Text_Sub_Color;
            padding: 0px;
        }
    }
    .analysisSettingsBody {
        padding-top: 16px;
        .fldLabel {
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            color: $EY_Light_Grey_Color;
            padding: 0px;
        }
        .fldPromptTypeGroup {
            margin-top: 6px;
        }
        .fldPromptTypeOption, .fldValFullTableContext {
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            width: auto;
            .promptTypeOptionValue {
                vertical-align: sub;
            }
            .iconInfo {
                float: right;
                opacity: 0.5;
                margin: 0px;
            }
            .motif-tooltip-wrapper {
                float: right;
            }
        }
        .fldValUseQnA {
            .motif-tooltip-wrapper {
                float: right;
            }
        }
        .fldPromptName {
            margin-top: 24px;
        }
        .fldValPromptName {
            font-size: 14px;
            font-weight: 300;
            line-height: 20px;
            padding-top: 6px;
        }
        .divider {
            border-top: 1px solid $EY_Divider_Color;
            margin-top: 24px;
            width: 100%;
        }
        .fldUseQnA, .fldFullTableContext, .fldExcelOutput {
            margin-top: 24px;
        }
        .fldPromptNameSelect, .fldValUseQnA, .fldValFullTableContext, .fldValExcelOutput {
            margin-top: 6px;
            .motif-checkbox-label {
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
            }
            .motif-select-input {
                padding: 0.2857142857rem 0.6428571429rem;
            }
        }
        .msgQA {
            font-family: $EY_Font;
            font-size: 12px;
            font-weight: 300;
            line-height: 18px;
            color: $EY_Light_Grey_Color;
        }
        .docsTab {
            display: grid;
            grid-auto-flow: column;
            .motif-active {
                color: $EY_Text_Color !important;
                font-weight: 400 !important;
            }
            .tabTitle {
                margin-right: 12px;
                vertical-align: super;
            }
        }
        .tabContentActive, .tabContentDeleted {
            padding-top: 24px;
        }
        .btnDocsTab:disabled {
            opacity: 0.5;
        }
        .iconInfo {
            vertical-align: middle;
        }
        .fldFullTableContext {
            .motif-checkbox-label {
                width: 100%;
            }
        }
        .fldUseQnA {
            .motif-checkbox-label {
                width: 100%;
            }
            .iconInfo {
                float: right;
                opacity: 0.5;
                margin: 0px;
            }
        }
    }
    .document-list-header {
        font-family: $EY_Font;
        font-weight: 300;
        font-size: 14;
        color: $EY_Light_Grey_Color;
    }
    .analysisSettingsFooter {
        display: table;
        text-align: right;
        .btnCancel, .btnConfirm , .btnNext, .btnBack {
            display: table-cell;
        }
        .btnCancel, .btnBack {
            margin-right: 15px;
        }
        .docWarning {
            margin-bottom: 24px;
            box-shadow: 0px 16px 32px 0px $EY_Toast_Box_Shadow_color;
            .motif-toast-icon-container img {
                align-self: start;
                margin-top: 12px;
            }
            .motif-toast-close-button {
                align-self: start;
                margin-top: 6px;
            }
        }
    }
    .document-item {
        display: flex;
        align-items: center;
        padding: 3px !important;
        background-color: $white;
        font-family: $EY_Font;
        font-size: 14px;
        font-weight: 300;
        line-height: 20px;
    }
    .tabContentDeletedContent {
        margin: 40px 0 0 0 !important;
    }
}

.tableAnalysisFooter {
    display: table !important;
    text-align: right;

    .btnCancel,
    .btnConfirm {
        display: table-cell;
    }

    .btnConfirm {
        margin-left: 15px;
    }
}

.tableAnalysisTextLines{
    font-family: $EY_Font;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    text-align: left;
    color: $EY_Text_Color;

}

