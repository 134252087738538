@import '../../../assets/styles/variables';

.prompt-details {
    font-family: $EY_Font;
    
    .prompt-container {
        overflow-y: auto;
        height: calc(100vh - 298px);
    }

    .view-edit-prompt {
        margin: 0.8rem;
    }

    .prompt-name {
        color: $EY_Text_Color;
        font-family: $EY_Font;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        margin-left: 0.5rem;
    }

    .prompt-div {
        border-bottom: 1px solid $EY_Home_Border_Color;
        display: flex;
        padding: 0.8rem;
        margin-top:0.2rem;
    }

    .field-label{
        color: $EY_Light_Grey_Color;
        font-family: $EY_Font;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
    }

    .field-value{
        color: $EY_Text_Color;
        font-family: $EY_Font;
        font-size: 14px;
        font-weight: 300;
        line-height: 20px;
        margin-top: 0.3rem;
        margin-bottom: 1.5rem;
    }

    .notifyToast {
        bottom: 0.8rem;
        position: sticky;
    }
}