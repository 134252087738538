@import '../../../assets/styles/variables';

.threshold-title {
  color: var(--Neutrals-Light-theme-900, $EY_Text_Color);
  font-family: $EY_Font;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 1rem;
  margin-top: 5%;
}

.threshold-default-title {
  color: var(--Neutrals-Light-theme-900, $EY_Text_Color);
  font-family: $EY_Font;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.threshold-content {
  color: var(--Neutrals-Light-theme-600, $EY_Text_Sub_Color);
  font-family: $EY_Font;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
  margin-bottom: 1rem;
  align-content: "center";
}

.threshold-mandatory {
  color: var(--Neutrals-Light-theme-900, $EY_Text_Color);
  font-family: $EY_Font;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
  margin-bottom: 1rem;
}

.breadcrumbLayer {
  padding-left: 0px;
  .btnBack {
    padding: 8px;
  }
}
.outerSpace {
  padding: 16px !important;
  border: 1px solid $EY_Divider_Color;
}
.threshold-container {
  height: calc(100vh - 198px);
  overflow-y: auto;
}
.projectThresholdName {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  vertical-align: text-bottom;
  margin-left: 8px;
  font-family: $EY_Font;
  color: $EY_Text_Color;
}